import {css} from "@emotion/react";

const chevronLeftIcon = require("../../app/images/chevron_left.svg");
const chevronRightIcon = require("../../app/images/chevron_right.svg");

export const slickSlider = css`
    .slick-list,
    .slick-slider,
    .slick-track {
        position: relative;
        display: block;
    }
    .slick-loading .slick-slide,
    .slick-loading .slick-track {
        visibility: hidden;
    }
    .slick-slider {
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: 0;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }
    .slick-slider .slick-list,
    .slick-slider .slick-track {
        transform: translate3d(0, 0, 0);
    }
    .slick-track {
        top: 0;
        left: 0;
    }
    .slick-track:after {
        clear: both;
    }
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }
    .slick-slide,
    .slick-slide > div,
    .slick-slide > div > div,
    .slick-slide > div > div > img {
        &:focus,
        &:active,
        &:focus:active {
            outline: 0;
            border: none;
        }
    }
    [dir="rtl"] .slick-slide {
        float: right;
    }
    .slick-slide img {
        display: block;
    }
    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-slide {
        display: block;
    }
    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }

    .slick-dots,
    .slick-next,
    .slick-prev {
        position: absolute;
        display: block;
        padding: 0;
    }
    .slick-loading .slick-list {
        //background: url(ajax-loader.gif) center center no-repeat #fff;
    }
    .slick-next,
    .slick-prev {
        font-size: 0;
        line-height: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: 0;
        background: 0 0;
    }

    .slick-arrow:focus,
    .slick-arrow:hover {
        outline: 0;
    }

    .slick-arrow:focus:before,
    .slick-arrow:hover:before {
        opacity: 1;
    }

    .slick-arrow.slick-disabled:before {
        opacity: 0.25;
    }

    .slick-arrow:before {
        font-size: 20px;
        line-height: 1;
        color: #fff;
    }
    .slick-prev {
        left: -25px;
    }
    [dir="rtl"] .slick-prev {
        right: -25px;
        left: auto;
    }
    .slick-prev:before {
        content: "";
    }
    .slick-next:before,
    [dir="rtl"] .slick-prev:before {
        content: "";
    }
    .slick-next {
        right: -25px;
    }
    [dir="rtl"] .slick-next {
        right: auto;
        left: -25px;
    }
    [dir="rtl"] .slick-next:before {
        content: "";
    }
    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }
    .slick-dots {
        bottom: -25px;
        width: 100%;
        margin: 0;
        list-style: none;
        text-align: center;
    }
    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }
    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: 0 0;
    }
    .slick-dots li button:focus,
    .slick-dots li button:hover {
        outline: 0;
    }
    .slick-dots li button:focus:before,
    .slick-dots li button:hover:before {
        opacity: 1;
    }
    .slick-dots li button:before {
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #000;
    }
    .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: #000;
    }

    .slick-prev {
        left: 0;

        &:before {
            background-image: url("${chevronLeftIcon}");
            left: 0;
            transform: translateX(-50%);
        }
    }

    .slick-next {
        right: 0;

        &:before {
            background-image: url("${chevronRightIcon}");
            right: -2.4rem;
        }
    }

    .slick-arrow {
        height: 100%;
        width: 100px;
        z-index: 1;

        &:before {
            z-index: 1;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            background-color: #ebff00;
            color: white;
            position: absolute;
            top: 50%;
            margin-top: -2.2rem;
            content: "";
            background-size: 50%;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .slick-disabled {
        display: none !important;
    }

    .slick-list {
        overflow: hidden;
    }
`;
import {css} from "@emotion/react";

const chevronLeftIcon = require("../../app/images/chevron_left.svg");
const chevronRightIcon = require("../../app/images/chevron_right.svg");

export const slickSlider = css`
    .slick-list,
    .slick-slider,
    .slick-track {
        position: relative;
        display: block;
    }
    .slick-loading .slick-slide,
    .slick-loading .slick-track {
        visibility: hidden;
    }
    .slick-slider {
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: 0;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }
    .slick-slider .slick-list,
    .slick-slider .slick-track {
        transform: translate3d(0, 0, 0);
    }
    .slick-track {
        top: 0;
        left: 0;
    }
    .slick-track:after {
        clear: both;
    }
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }
    .slick-slide,
    .slick-slide > div,
    .slick-slide > div > div,
    .slick-slide > div > div > img {
        &:focus,
        &:active,
        &:focus:active {
            outline: 0;
            border: none;
        }
    }
    [dir="rtl"] .slick-slide {
        float: right;
    }
    .slick-slide img {
        display: block;
    }
    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-slide {
        display: block;
    }
    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }

    .slick-dots,
    .slick-next,
    .slick-prev {
        position: absolute;
        display: block;
        padding: 0;
    }
    .slick-loading .slick-list {
        //background: url(ajax-loader.gif) center center no-repeat #fff;
    }
    .slick-next,
    .slick-prev {
        font-size: 0;
        line-height: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: 0;
        background: 0 0;
    }

    .slick-arrow:focus,
    .slick-arrow:hover {
        outline: 0;
    }

    .slick-arrow:focus:before,
    .slick-arrow:hover:before {
        opacity: 1;
    }

    .slick-arrow.slick-disabled:before {
        opacity: 0.25;
    }

    .slick-arrow:before {
        font-size: 20px;
        line-height: 1;
        color: #fff;
    }
    .slick-prev {
        left: -25px;
    }
    [dir="rtl"] .slick-prev {
        right: -25px;
        left: auto;
    }
    .slick-prev:before {
        content: "";
    }
    .slick-next:before,
    [dir="rtl"] .slick-prev:before {
        content: "";
    }
    .slick-next {
        right: -25px;
    }
    [dir="rtl"] .slick-next {
        right: auto;
        left: -25px;
    }
    [dir="rtl"] .slick-next:before {
        content: "";
    }
    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }
    .slick-dots {
        bottom: -25px;
        width: 100%;
        margin: 0;
        list-style: none;
        text-align: center;
    }
    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }
    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: 0 0;
    }
    .slick-dots li button:focus,
    .slick-dots li button:hover {
        outline: 0;
    }
    .slick-dots li button:focus:before,
    .slick-dots li button:hover:before {
        opacity: 1;
    }
    .slick-dots li button:before {
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #000;
    }
    .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: #000;
    }

    .slick-prev {
        left: 0;

        &:before {
            background-image: url("${chevronLeftIcon}");
            left: 0;
            transform: translateX(-50%);
        }
    }

    .slick-next {
        right: 0;

        &:before {
            background-image: url("${chevronRightIcon}");
            right: -2.4rem;
        }
    }

    .slick-arrow {
        height: 100%;
        width: 100px;
        z-index: 1;

        &:before {
            z-index: 1;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            background-color: #ebff00;
            color: white;
            position: absolute;
            top: 50%;
            margin-top: -2.2rem;
            content: "";
            background-size: 50%;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .slick-disabled {
        display: none !important;
    }

    .slick-list {
        overflow: hidden;
    }
`;
