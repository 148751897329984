import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {IUserPreferencesFilterKeys} from "../interfaces/IUserPreferences";

export enum FilterPresets {
    All,
    WithoutRegion
}

const filterToEliminateTypesConfig: Record<FilterPresets, IUserPreferencesFilterKeys[]> = {
    [FilterPresets.All]: [],
    [FilterPresets.WithoutRegion]: ["region"]
};

export const useUserPreferencesFilters = (typeOfFilters = FilterPresets.All) => {
    const userPreferences = useSelector((state: IRPStore) => state.user.preferences);
    const userPreferencesFilters = userPreferences?.data?.filters;

    return useMemo(() => {
        if (!userPreferencesFilters || typeOfFilters === FilterPresets.All) {
            return {};
        }

        const filtersToEliminate = filterToEliminateTypesConfig[typeOfFilters];

        return Object.entries(userPreferencesFilters).reduce(
            (acc, [key, value]) => {
                if (!filtersToEliminate.includes(key as IUserPreferencesFilterKeys)) {
                    acc[key] = value;
                }

                return acc;
            },
            {} as Record<string, string | string[]>
        ) as Partial<typeof userPreferencesFilters>;
    }, [typeOfFilters, userPreferencesFilters]);
};
import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {IUserPreferencesFilterKeys} from "../interfaces/IUserPreferences";

export enum FilterPresets {
    All,
    WithoutRegion
}

const filterToEliminateTypesConfig: Record<FilterPresets, IUserPreferencesFilterKeys[]> = {
    [FilterPresets.All]: [],
    [FilterPresets.WithoutRegion]: ["region"]
};

export const useUserPreferencesFilters = (typeOfFilters = FilterPresets.All) => {
    const userPreferences = useSelector((state: IRPStore) => state.user.preferences);
    const userPreferencesFilters = userPreferences?.data?.filters;

    return useMemo(() => {
        if (!userPreferencesFilters || typeOfFilters === FilterPresets.All) {
            return {};
        }

        const filtersToEliminate = filterToEliminateTypesConfig[typeOfFilters];

        return Object.entries(userPreferencesFilters).reduce(
            (acc, [key, value]) => {
                if (!filtersToEliminate.includes(key as IUserPreferencesFilterKeys)) {
                    acc[key] = value;
                }

                return acc;
            },
            {} as Record<string, string | string[]>
        ) as Partial<typeof userPreferencesFilters>;
    }, [typeOfFilters, userPreferencesFilters]);
};
