import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum GTMEvent {
    LISTING_CHANGING_BUTTON = "search_buttons"
}

const searchEvent = (event: GTMEvent, data: Record<string, string> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const propertyConfiguratorAnalytics = {
    gtm: {
        searchEvent
    }
};

export const gtmSearch = (searchTerm: string, category: string, label: string) => {
    const gtmData = {
        event: "search",
        search_term: searchTerm,
        event_category: category,
        event_label: label
    };
    hitGoogleTagManager(gtmData);
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum GTMEvent {
    LISTING_CHANGING_BUTTON = "search_buttons"
}

const searchEvent = (event: GTMEvent, data: Record<string, string> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const propertyConfiguratorAnalytics = {
    gtm: {
        searchEvent
    }
};

export const gtmSearch = (searchTerm: string, category: string, label: string) => {
    const gtmData = {
        event: "search",
        search_term: searchTerm,
        event_category: category,
        event_label: label
    };
    hitGoogleTagManager(gtmData);
};
