import React, {useEffect} from "react";
import {Route, Switch, useLocation} from "react-router";

import {usePrevious} from "@pg-mono/hooks";
import {isEqual, omit} from "@pg-mono/nodash";
import {parseSearch} from "@pg-mono/request";

export const scrollToTop = () => {
    setTimeout(() => {
        try {
            window && window.scroll(0, 0);
        } catch {
            // SSR
        }
    }, 0);
};

// NOTE: right now we redirect on every view change, so we need to validate this condition when we will have actual SPA
// NOTE: for now we use this logic to offer-list only
const DefaultScrollToTop = () => {
    const location = useLocation();

    const prevPathname = usePrevious(location.pathname, location.pathname); // we watch only change
    const prevSearch = usePrevious(location.search, location.search); // we watch only change

    useEffect(() => {
        const prevQuery = omit(parseSearch(prevSearch), ["page"]);
        const query = omit(parseSearch(location.search), ["page"]);
        // Scroll when pathname or query params change
        if (prevPathname !== location.pathname || !isEqual(query, prevQuery)) {
            scrollToTop();
        }
    }, [location.pathname]); // trigger only on view change

    // This is only functional (containing logic) component - no visual representation
    return null;
};

export const ScrollToTop = () => {
    return (
        <Switch>
            <Route component={DefaultScrollToTop} />
        </Switch>
    );
};
import React, {useEffect} from "react";
import {Route, Switch, useLocation} from "react-router";

import {usePrevious} from "@pg-mono/hooks";
import {isEqual, omit} from "@pg-mono/nodash";
import {parseSearch} from "@pg-mono/request";

export const scrollToTop = () => {
    setTimeout(() => {
        try {
            window && window.scroll(0, 0);
        } catch {
            // SSR
        }
    }, 0);
};

// NOTE: right now we redirect on every view change, so we need to validate this condition when we will have actual SPA
// NOTE: for now we use this logic to offer-list only
const DefaultScrollToTop = () => {
    const location = useLocation();

    const prevPathname = usePrevious(location.pathname, location.pathname); // we watch only change
    const prevSearch = usePrevious(location.search, location.search); // we watch only change

    useEffect(() => {
        const prevQuery = omit(parseSearch(prevSearch), ["page"]);
        const query = omit(parseSearch(location.search), ["page"]);
        // Scroll when pathname or query params change
        if (prevPathname !== location.pathname || !isEqual(query, prevQuery)) {
            scrollToTop();
        }
    }, [location.pathname]); // trigger only on view change

    // This is only functional (containing logic) component - no visual representation
    return null;
};

export const ScrollToTop = () => {
    return (
        <Switch>
            <Route component={DefaultScrollToTop} />
        </Switch>
    );
};
