import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const SearchBoldIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.756 8.578 7.64 6.462a4.138 4.138 0 0 0 .692-2.295A4.171 4.171 0 0 0 4.167 0 4.171 4.171 0 0 0 0 4.167a4.171 4.171 0 0 0 4.167 4.166c.848 0 1.636-.255 2.295-.692l2.116 2.115a.833.833 0 1 0 1.178-1.178ZM1.25 4.167A2.92 2.92 0 0 1 4.167 1.25a2.92 2.92 0 0 1 2.916 2.917 2.92 2.92 0 0 1-2.916 2.916A2.92 2.92 0 0 1 1.25 4.167Z" />{" "}
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const SearchBoldIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.756 8.578 7.64 6.462a4.138 4.138 0 0 0 .692-2.295A4.171 4.171 0 0 0 4.167 0 4.171 4.171 0 0 0 0 4.167a4.171 4.171 0 0 0 4.167 4.166c.848 0 1.636-.255 2.295-.692l2.116 2.115a.833.833 0 1 0 1.178-1.178ZM1.25 4.167A2.92 2.92 0 0 1 4.167 1.25a2.92 2.92 0 0 1 2.916 2.917 2.92 2.92 0 0 1-2.916 2.916A2.92 2.92 0 0 1 1.25 4.167Z" />{" "}
        </SvgIcon>
    );
};
