import {RefObject, useEffect, useRef, useState} from "react";

export const useLastOfferBoxSize = (lastOfferBoxElementRef: RefObject<Element> | null) => {
    const [slickLastOfferBoundingRect, setSlickLastOfferBoundingRect] = useState<DOMRect | null>(null);
    const resizeObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if ("ResizeObserver" in window) {
            resizeObserverRef.current = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    setSlickLastOfferBoundingRect(entry.contentRect);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!lastOfferBoxElementRef?.current) {
            return;
        }
        resizeObserverRef.current?.observe(lastOfferBoxElementRef.current);
        return () => {
            if (lastOfferBoxElementRef.current) {
                resizeObserverRef.current?.unobserve(lastOfferBoxElementRef.current);
            }
            resizeObserverRef.current?.disconnect();
        };
    }, [lastOfferBoxElementRef?.current]);

    return slickLastOfferBoundingRect;
};
import {RefObject, useEffect, useRef, useState} from "react";

export const useLastOfferBoxSize = (lastOfferBoxElementRef: RefObject<Element> | null) => {
    const [slickLastOfferBoundingRect, setSlickLastOfferBoundingRect] = useState<DOMRect | null>(null);
    const resizeObserverRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        if ("ResizeObserver" in window) {
            resizeObserverRef.current = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    setSlickLastOfferBoundingRect(entry.contentRect);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!lastOfferBoxElementRef?.current) {
            return;
        }
        resizeObserverRef.current?.observe(lastOfferBoxElementRef.current);
        return () => {
            if (lastOfferBoxElementRef.current) {
                resizeObserverRef.current?.unobserve(lastOfferBoxElementRef.current);
            }
            resizeObserverRef.current?.disconnect();
        };
    }, [lastOfferBoxElementRef?.current]);

    return slickLastOfferBoundingRect;
};
