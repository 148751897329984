import {format, isFuture, isValid, parseISO} from "date-fns";
import {pl} from "date-fns/locale";

export const formatPL = (date: Date, formatStr: string) => {
    return format(date, formatStr, {
        locale: pl
    });
};

export const dateTimeFormat = {
    standard: "dd MMMM yyyy",
    shortDate: "dd.MM.yyyy",
    // longDate: "d MMMM yyyy",
    shortDateTime: "dd.MM.yyyy HH:mm",
    longDateTime: "d MMMM yyyy, HH:mm",
    // time: "HH:mm",
    quarter: "Q 'kw.' yyyy",
    quarterLong: "Q 'kwartał' yyyy",
    // year: "yyyy",
    dashedDateFormat: "yyyy-MM-dd",
    // verboseMonthDatePL: "LL"
    // verboseShortMonthDatePL: "ll",
    // verboseLongDayOfWeekPL: "dddd",
    // numeralDayOfWeek: "d",
    // numeralDayOfMonth: "D",
    // verboseMonthNamePL: "MMMM"
    shortMonthNamePL: "MMM"
};

export const formatDate = (dateString: string | null = null, dateFormat = dateTimeFormat.standard): string => {
    const date = dateString ? parseISO(dateString) : new Date();
    return isValid(date) ? formatPL(date, dateFormat) : "";
};

export const formatFutureDate = (dateString: string | null = null, dateFormat = dateTimeFormat.standard, readyPhrase?: string): string => {
    const date = dateString ? parseISO(dateString) : new Date();
    return isValid(date) ? (isFuture(date) ? formatPL(date, dateFormat) : readyPhrase ? readyPhrase : "gotowe") : "";
};

export const quarterToRomanNumerals = (value: string, quarterLabel = "kw.") => {
    const quarterLabelIndex = value.indexOf(quarterLabel);
    const quarterValue = value.slice(0, quarterLabelIndex).trim();

    if (quarterLabelIndex === -1 || !quarterValue) {
        return value;
    }

    return `${value.slice(0, quarterLabelIndex).replace(quarterValue, ["I", "II", "III", "IV"][parseInt(quarterValue) - 1])}${value.slice(quarterLabelIndex)}`;
};

export const monthNominativeVerbose = (month: number) => {
    switch (month) {
        case 1:
            return "ze stycznia";
        case 2:
            return "z lutego";
        case 3:
            return "z marca";
        case 4:
            return "z kwietnia";
        case 5:
            return "z maja";
        case 6:
            return "z czerwca";
        case 7:
            return "z lipca";
        case 8:
            return "z sierpnia";
        case 9:
            return "z września";
        case 10:
            return "z października";
        case 11:
            return "z listopada";
        case 12:
            return "z grudnia";
        default:
            return;
    }
};

export const monthWhenVerbose = (month: number) => {
    switch (month) {
        case 1:
            return "styczniu";
        case 2:
            return "lutym";
        case 3:
            return "marcu";
        case 4:
            return "kwietniu";
        case 5:
            return "maju";
        case 6:
            return "czerwcu";
        case 7:
            return "lipcu";
        case 8:
            return "sierpniu";
        case 9:
            return "wrześniu";
        case 10:
            return "październiku";
        case 11:
            return "listopadzie";
        case 12:
            return "grudniu";
        default:
            return;
    }
};

export const monthDeclension = (month: number) => {
    switch (month) {
        case 0:
            return "stycznia";
        case 1:
            return "lutego";
        case 2:
            return "marca";
        case 3:
            return "kwietnia";
        case 4:
            return "maja";
        case 5:
            return "czerwca";
        case 6:
            return "lipca";
        case 7:
            return "sierpnia";
        case 8:
            return "września";
        case 9:
            return "października";
        case 10:
            return "listopada";
        case 11:
            return "grudnia";
        default:
            throw new Error("Wrong month number");
    }
};
import {format, isFuture, isValid, parseISO} from "date-fns";
import {pl} from "date-fns/locale";

export const formatPL = (date: Date, formatStr: string) => {
    return format(date, formatStr, {
        locale: pl
    });
};

export const dateTimeFormat = {
    standard: "dd MMMM yyyy",
    shortDate: "dd.MM.yyyy",
    // longDate: "d MMMM yyyy",
    shortDateTime: "dd.MM.yyyy HH:mm",
    longDateTime: "d MMMM yyyy, HH:mm",
    // time: "HH:mm",
    quarter: "Q 'kw.' yyyy",
    quarterLong: "Q 'kwartał' yyyy",
    // year: "yyyy",
    dashedDateFormat: "yyyy-MM-dd",
    // verboseMonthDatePL: "LL"
    // verboseShortMonthDatePL: "ll",
    // verboseLongDayOfWeekPL: "dddd",
    // numeralDayOfWeek: "d",
    // numeralDayOfMonth: "D",
    // verboseMonthNamePL: "MMMM"
    shortMonthNamePL: "MMM"
};

export const formatDate = (dateString: string | null = null, dateFormat = dateTimeFormat.standard): string => {
    const date = dateString ? parseISO(dateString) : new Date();
    return isValid(date) ? formatPL(date, dateFormat) : "";
};

export const formatFutureDate = (dateString: string | null = null, dateFormat = dateTimeFormat.standard, readyPhrase?: string): string => {
    const date = dateString ? parseISO(dateString) : new Date();
    return isValid(date) ? (isFuture(date) ? formatPL(date, dateFormat) : readyPhrase ? readyPhrase : "gotowe") : "";
};

export const quarterToRomanNumerals = (value: string, quarterLabel = "kw.") => {
    const quarterLabelIndex = value.indexOf(quarterLabel);
    const quarterValue = value.slice(0, quarterLabelIndex).trim();

    if (quarterLabelIndex === -1 || !quarterValue) {
        return value;
    }

    return `${value.slice(0, quarterLabelIndex).replace(quarterValue, ["I", "II", "III", "IV"][parseInt(quarterValue) - 1])}${value.slice(quarterLabelIndex)}`;
};

export const monthNominativeVerbose = (month: number) => {
    switch (month) {
        case 1:
            return "ze stycznia";
        case 2:
            return "z lutego";
        case 3:
            return "z marca";
        case 4:
            return "z kwietnia";
        case 5:
            return "z maja";
        case 6:
            return "z czerwca";
        case 7:
            return "z lipca";
        case 8:
            return "z sierpnia";
        case 9:
            return "z września";
        case 10:
            return "z października";
        case 11:
            return "z listopada";
        case 12:
            return "z grudnia";
        default:
            return;
    }
};

export const monthWhenVerbose = (month: number) => {
    switch (month) {
        case 1:
            return "styczniu";
        case 2:
            return "lutym";
        case 3:
            return "marcu";
        case 4:
            return "kwietniu";
        case 5:
            return "maju";
        case 6:
            return "czerwcu";
        case 7:
            return "lipcu";
        case 8:
            return "sierpniu";
        case 9:
            return "wrześniu";
        case 10:
            return "październiku";
        case 11:
            return "listopadzie";
        case 12:
            return "grudniu";
        default:
            return;
    }
};

export const monthDeclension = (month: number) => {
    switch (month) {
        case 0:
            return "stycznia";
        case 1:
            return "lutego";
        case 2:
            return "marca";
        case 3:
            return "kwietnia";
        case 4:
            return "maja";
        case 5:
            return "czerwca";
        case 6:
            return "lipca";
        case 7:
            return "sierpnia";
        case 8:
            return "września";
        case 9:
            return "października";
        case 10:
            return "listopada";
        case 11:
            return "grudnia";
        default:
            throw new Error("Wrong month number");
    }
};
