import React from "react";

import {ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {ILatestQuery} from "../../utils/latest_query";
import {OfferBox} from "../components/offer_box/OfferBox";
import {useOfferDetailsActions} from "../hooks/use_offer_details_actions";
import {IModalPropertyListOffer} from "../list/components/OfferListPropertiesModal";
import {IOfferBoxOffer} from "../types/IOfferBoxOffer";

interface IProps {
    offer: IOfferBoxWithPropertiesModalOffer;
    unlinkVendor?: boolean;
    sourceSection?: ApplicationSourceSection;
    mainPage?: boolean;
    shouldLinkToOffer?: boolean;
    onClick?: () => void;
    index?: number;
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
}

export type IOfferBoxWithPropertiesModalOffer = IOfferBoxOffer & IModalPropertyListOffer;

export function OfferBoxWithOfferDetailsAction(props: IProps) {
    const {unlinkVendor, mainPage} = props;

    const {showOfferDetailsGeneralHandler} = useOfferDetailsActions();

    return (
        <OfferBox
            offer={props.offer}
            onShowOfferDetailsBtnClick={showOfferDetailsGeneralHandler}
            offerDetailsBtnText={props.offer.configuration.pre_sale ? "Zobacz inwestycję" : undefined}
            unlinkVendor={unlinkVendor}
            disableFavInfoText={mainPage}
            shouldLinkToOffer={props.shouldLinkToOffer}
            onClick={props.onClick}
            index={props.index}
            latestQuery={props.latestQuery}
        />
    );
}
import React from "react";

import {ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {ILatestQuery} from "../../utils/latest_query";
import {OfferBox} from "../components/offer_box/OfferBox";
import {useOfferDetailsActions} from "../hooks/use_offer_details_actions";
import {IModalPropertyListOffer} from "../list/components/OfferListPropertiesModal";
import {IOfferBoxOffer} from "../types/IOfferBoxOffer";

interface IProps {
    offer: IOfferBoxWithPropertiesModalOffer;
    unlinkVendor?: boolean;
    sourceSection?: ApplicationSourceSection;
    mainPage?: boolean;
    shouldLinkToOffer?: boolean;
    onClick?: () => void;
    index?: number;
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
}

export type IOfferBoxWithPropertiesModalOffer = IOfferBoxOffer & IModalPropertyListOffer;

export function OfferBoxWithOfferDetailsAction(props: IProps) {
    const {unlinkVendor, mainPage} = props;

    const {showOfferDetailsGeneralHandler} = useOfferDetailsActions();

    return (
        <OfferBox
            offer={props.offer}
            onShowOfferDetailsBtnClick={showOfferDetailsGeneralHandler}
            offerDetailsBtnText={props.offer.configuration.pre_sale ? "Zobacz inwestycję" : undefined}
            unlinkVendor={unlinkVendor}
            disableFavInfoText={mainPage}
            shouldLinkToOffer={props.shouldLinkToOffer}
            onClick={props.onClick}
            index={props.index}
            latestQuery={props.latestQuery}
        />
    );
}
