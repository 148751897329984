import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputValue} from "../components/ISearchInputValue";

export const getSearchTerm = (searchValue: ISearchInputValue) => {
    if (searchValue.tabType === SearchTab.Regions && searchValue.regions.length > 0) {
        return searchValue.regions[0].name;
    }
    if (searchValue.tabType === SearchTab.Offers) {
        return searchValue.offer.name;
    }
    if (searchValue.tabType === SearchTab.Vendors) {
        return searchValue.vendor.name;
    }
    if (searchValue.tabType === SearchTab.Places) {
        return searchValue.place.description;
    }
    return "";
};
import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputValue} from "../components/ISearchInputValue";

export const getSearchTerm = (searchValue: ISearchInputValue) => {
    if (searchValue.tabType === SearchTab.Regions && searchValue.regions.length > 0) {
        return searchValue.regions[0].name;
    }
    if (searchValue.tabType === SearchTab.Offers) {
        return searchValue.offer.name;
    }
    if (searchValue.tabType === SearchTab.Vendors) {
        return searchValue.vendor.name;
    }
    if (searchValue.tabType === SearchTab.Places) {
        return searchValue.place.description;
    }
    return "";
};
