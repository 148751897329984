import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, flexAbsoluteCenter, mb, p, pointer} from "@pg-design/helpers-css";
import {BrandSkyscrapersIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

interface IProps {
    link: string;
    text: string;
    onClick?: () => void;
}

export const MoreOffersBox = (props: IProps) => {
    const theme = useTheme();
    const target = useResponsiveLinkTarget();

    return (
        <a css={moreOffersBox} href={props.link} target={target} onClick={props.onClick}>
            <BrandSkyscrapersIcon css={mb(3)} size="12" wrapperColor={theme.colors.primary} wrapperSize="12" />

            <Text as="p" align="center" variant="headline_3">
                {props.text}
            </Text>
        </a>
    );
};

const moreOffersBox = (theme: Theme) => css`
    ${elevation(2)};
    ${borderRadius(2)};
    ${flexAbsoluteCenter};
    ${pointer};
    ${p(2)};
    flex-direction: column;
    min-width: 288px;
    max-width: 375px;
    width: 100%;
    min-height: 40.6rem;
    height: 100%;
    background-color: #fff;

    &:hover,
    &:focus {
        color: ${theme.colors.secondary};
    }
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, flexAbsoluteCenter, mb, p, pointer} from "@pg-design/helpers-css";
import {BrandSkyscrapersIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

interface IProps {
    link: string;
    text: string;
    onClick?: () => void;
}

export const MoreOffersBox = (props: IProps) => {
    const theme = useTheme();
    const target = useResponsiveLinkTarget();

    return (
        <a css={moreOffersBox} href={props.link} target={target} onClick={props.onClick}>
            <BrandSkyscrapersIcon css={mb(3)} size="12" wrapperColor={theme.colors.primary} wrapperSize="12" />

            <Text as="p" align="center" variant="headline_3">
                {props.text}
            </Text>
        </a>
    );
};

const moreOffersBox = (theme: Theme) => css`
    ${elevation(2)};
    ${borderRadius(2)};
    ${flexAbsoluteCenter};
    ${pointer};
    ${p(2)};
    flex-direction: column;
    min-width: 288px;
    max-width: 375px;
    width: 100%;
    min-height: 40.6rem;
    height: 100%;
    background-color: #fff;

    &:hover,
    &:focus {
        color: ${theme.colors.secondary};
    }
`;
